<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
      <CCard class="card-simple">
        <CCardBody>
          <CRow class="justify-content-center mb-3">
            <CCol sm="11" lg="9" style="margin-bottom:10px;">
              <CInput
                v-model.trim="$v.rol.StowageRoleName.$model"
                v-uppercase
                :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                :invalid-feedback="errorMessage($v.rol.StowageRoleName)"
                :is-valid="hasError($v.rol.StowageRoleName)"
                :label="$t('label.name')"
                :placeholder="$t('label.role')+' '+$t('label.name')"
                addLabelClasses="required text-right"
                maxlength="50"
              />
            </CCol>
            <CCol sm="11" lg="1" class="px-0" v-if="isEdit" style="margin-bottom:10px;">
              <CButton color="wipe" size="sm" class="mr-1" @click="resetForm" v-c-tooltip="{content: $t('label.clearFields')}" >
                <CIcon name="cil-brush-alt"/>
              </CButton>
            </CCol>
            <CCol sm="11" lg="2" class="px-0" style="margin-bottom:10px;">
              <CButton color="edit" size="sm" class="mr-1" @click="showTableCollapse = !showTableCollapse" v-c-tooltip="{content: $t('label.roleList')}" >
                {{ textCheck }}
                <CIcon :name="iconCheck"/>
              </CButton>
            </CCol>
            <CCol sm="11" lg="9"> 
              <CRow>
                <label class="col-form-label col-sm-12 col-lg-4 text-right ml-2 pt-1">
                  {{$t('label.supervisor')}}
                </label>
                <CSwitch
                  color="watch"
                  variant="3d"
                  type="checkbox"
                  :checked.sync="rol.FgSupervisor"         
                />
                <label class="col-form-label col-sm-12 col-lg-5 text-right pt-1">
                  {{$t('label.applySigetMobile')}}
                </label>
                <CSwitch
                  color="watch"
                  variant="3d"
                  type="checkbox"
                  :checked.sync="rol.FgApplyTablet"         
                />
              </CRow>     
            </CCol>  
            <CCol sm="11" lg="1" class="px-0" v-if="isEdit" style="margin-bottom:10px;">
            </CCol>
            <CCol sm="11" lg="2"></CCol>        
            <CCol sm="11" lg="9" v-if="isEdit">
              <CSelect
                :label="$t('label.status')"
                addLabelClasses="required text-right"
                :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                v-model="rol.Status"
                :value.sync="rol.Status"
                :options="selectOptions"
                :is-valid="statusSelectColor2"
              />
            </CCol>
            <CCol sm="11" lg="3" v-if="isEdit">
            </CCol>
          </CRow> 
          <CCollapse :show="showTableCollapse" class="mt-5">
            <dataTableExtended
                  class="align-center-row-datatable"
                  :items="formatedItems"
                  :fields="fields"
                  :loading="Loading"
                  column-filter
                  :noItemsView="tableText.noItemsViewText"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items-per-page="5"
                  hover
                  small
                  sorter
                  pagination  
                >
                  <template #loading>
                    <loading/>
                  </template>
                  <template #Status="{item}">
                    <td class="center-cell">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #Detalle="{item}">
                  <td class="center-cell  py-2">
                    <CButton color="edit" class="mr-1" size="sm" 
                      v-c-tooltip="{
                        content: $t('label.edit')+' '+$t('label.role'),
                        placement: 'top-end'
                      }"
                    @click="editItem(item)"   
                    >
                      <CIcon name="pencil" />
                    </CButton>
                  </td>
                </template>
                </dataTableExtended>
          </CCollapse>   
      </CCardBody>
    </CCard>
    <CRow>
      <CCol sm="11" lg="12" class="d-flex justify-content-end footer">
        <CButton outline color="add" class="m-2" @click="statusConfirmation()" >
        <CIcon name="checkAlt"/>&nbsp;
        {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe"  class="m-2" @click="close">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import SingleRoleFormValidation from '@/_validations/estiba/SingleRoleFormValidation';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';

//Data
function data() {
  return {
    isSubmit: false,
    Loading: false,
    rol: {
      StowageRoleId: '',
      StowageRoleName: '',
      FgApplyTablet: false,
      FgSupervisor: false,
      Status: 1,
      FgStatus: 1
    },
    stowageRoleName: '',
    iconoCollapse: 'cil-chevron-top-alt',
    textoCollapse: '',
    showTableCollapse: false,
    items: [],
    isEdit: false,
    selectOptions: [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ],
  }
}

async function StowageRolJoin(idRol) {
  let IdTipoBuque= '';

  if(this.$store.state.planificacionestiba.TpVesselId)
    IdTipoBuque = this.$store.state.planificacionestiba.TpVesselId;

  if(this.$store.state.visitas.itinerarySelected.TpVesselId)
    IdTipoBuque = this.$store.state.visitas.itinerarySelected.TpVesselId;

  let StowageRoleVesselJson = {
    StowageRoleId: idRol,
    TpVesselId: IdTipoBuque
  };

  if (IdTipoBuque!=null || IdTipoBuque!='') {
    await this.$http.post("StowageRoleVessel-insert", StowageRoleVesselJson, { root: 'StowageRoleVesselJson' })
    .then(response => {
      return true;
    }).catch(err => {
      return false;
    })
  } else {
    return false;
  } 
}

//Method
function submit() {
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.rol.$error) {
      this.Loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
     
    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'StowageRole-update' : 'StowageRole-insert'; 
    let StowageRoleJson = this.isEdit
      ? {
          StowageRoleId: this.rol.StowageRoleId,
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet  ? 1:0,
          FgSupervisor: this.rol.FgSupervisor ? 1:0,
          Status: this.rol.Status
        }
      : { 
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet ? 1:0,
          FgSupervisor: this.rol.FgSupervisor ? 1:0,
        };

    this.$http.ejecutar(metodo, ruta, StowageRoleJson, { root: 'StowageRoleJson' })
    .then(response => {
      if (metodo=='POST') {
        this.StowageRolJoin(response.data.data[0].StowageRoleId);
      }
      this.getRolesList();
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
      
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.resetForm();
      //this.$emit('child-refresh',true);
      this.isSubmit = false;
      this.Loading = false;
    });
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function resetForm() {
  this.rol.StowageRoleId = '';
  this.rol.StowageRoleName = '';
  this.rol.Status = 1;
  this.rol.FgStatus = 1;
  this.rol.FgApplyTablet = false;
  this.rol.FgSupervisor = false;
  this.isEdit= false;
  this.$nextTick(() => { this.$v.$reset() })
}

function close() {
  this.resetForm();
  this.showTableCollapse = false;
  this.$emit('child-refresh',true);
}

function getRolesList() {
 //this.Loading = true;
  this.$http.get("StowageRole-list", {
      filter: 'ALL'
  }).then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    //this.Loading = false;
  });
    
}

function statusConfirmation() {
  if (this.isEdit && (this.rol.Status !== this.rol.FgStatus)) {
    this.$swal.fire({
      text: `${this.$t('label.changeStatusQuestion')+' '} ${this.rol.StowageRoleName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        this.submit();
      };
    });
  } else this.submit();
}

function editItem(item) {
  this.rol.StowageRoleId   = item.StowageRoleId;
  this.rol.StowageRoleName = item.StowageRoleName;
  this.rol.FgApplyTablet = item.FgApplyTablet == true ? true : false;
  this.stowageRoleName = item.StowageRoleName;
  this.rol.Status = item.FgActStowageRole ? 1:0;
  this.rol.FgStatus = item.FgActStowageRole ? 1:0;
  this.rol.FgSupervisor = item.FgSupervisor;

  this.isEdit = true;
  this.$v.$touch();
}

  function statusSelectColor2(){
    if  (this.rol.Status){
      return this.rol.Status === 1;
    } else {
      return false;
    }
  }
//Computed
function selectOptionsFgApplyTablet(){
  return[
    { 
      value: 0, 
      label: 'NO',
    },
    { 
      value: 1, 
      label: this.$t('label.yes')
    }
  ]
}

function iconCheck() {
  return this.iconoCollapse = (this.showTableCollapse) ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
}
function textCheck() {
  return this.textoCollapse = (this.showTableCollapse) ? this.$t("label.close") : this.$t("label.toView");
}

function fields(){ 
  return [
    { key: 'Nro', label: '#', filter: false, _style: 'width:1%; text-align:center;' },
    { key: 'StowageRoleName', label: this.$t('label.stowageRole'), _style:'width:15%', _classes: 'text-uppercase' },
    { key: 'FgApplyTabletLabel', label: 'SIGET MOBILE', _style: 'width: 15%;', _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes: 'text-uppercase' },
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%;', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width: 10%;', _classes:'text-center'},
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style:'min-width:45px; width:5%;',
      _classes: 'text-center'
    }
  ]
}

function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    Nro: item.Nro,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    FgApplyTabletLabel: item.FgApplyTablet ? this.$t('label.yes') : 'NO',
    FgApplyTablet: item.FgApplyTablet,
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}

export default {
  name:  'add-role',
  mixins: [General],
  data,
  props: {
    value: null,
    clearCollapse: '',
  },
  directives: UpperCase,
  validations: SingleRoleFormValidation,
  mounted() {
    this.showTableCollapse = false;
  },
  watch: {
    clearCollapse: async function (val) {
      if (val==0) {
        await this.resetForm();
        await this.getRolesList();
      }
    }
  },
  methods: {
    submit,
    close,
    resetForm,
    StowageRolJoin,
    getRolesList,
    statusConfirmation,
    editItem,
    statusSelectColor2,
  },
  computed: {
    selectOptionsFgApplyTablet,
    iconCheck,
    textCheck,
    fields, 
    formatedItems,
  },
}
</script>

<style lang="scss">
  .card-simple{
    border: 0px;
    margin-bottom: 0 !important;
  }
</style>